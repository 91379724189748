<template>
  <PageContainer
    :title="$translations.meditation.title"
    :back="actions.back">
    <template v-slot:content>
      <div class="page-segment">
        <div class="page-segment-content">
          <MeditationCard
            class="margin-bottom-sm"
            style="--carrousel-item-width: 340px;"
            v-for="(item, index) in meditations"
            :key="index"
            :meditation="item"
            @clicked="callSegmentEvent(item)"
          />
        </div>
      </div>
    </template>
  </PageContainer>
</template>

<script>
import { PageContainer, Settings, MeditationCard } from '@seliaco/red-panda'
import { HomeEvent } from '@/types/events'

export default {
  components: { PageContainer, MeditationCard },
  data () {
    return {
      meditations: null,
      actions: {
        back: {
          callback: () => {
            this.$router.replace({
              name: 'Home'
            })
          }
        }
      }
    }
  },
  mounted () {
    this.getMeditations()
  },
  methods: {
    async getMeditations () {
      const setting = await Settings.read({
        columns: 'value',
        eq: {
          key: 'SELIA_MEDITATIONS'
        }
      }).catch(() => null)

      if (setting?.data[0]?.value) {
        this.meditations = JSON.parse(setting.data[0].value)
      }
    },
    callSegmentEvent (item) {
      const body = {
        user: this.$store.getters['auth/user'],
        plaftorm: this.$store.getters.platform,
        version: this.$version,
        meditation: item.title,
        page: this.$route.name
      }
      HomeEvent.Home_Meditations_Start_Click(body)
    }
  }
}
</script>
